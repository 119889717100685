define('bd-onko/services/export', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		windowManager: _ember['default'].inject.service('window-manager'),
		exportExcel: _ember['default'].inject.service('export-excel'),
		availableFormats: ['xlsx'],
		startExportWizard: function startExportWizard(data) {
			var self = this;

			if (typeof data.get === 'undefined') {
				data = _ember['default'].A(data);
			}

			var columns = this.fetchAvailableProperties(data);

			var windowManager = this.get('windowManager');

			var modal = {
				type: 'export-wizard',
				options: {
					availableColumns: columns,
					availableFormats: this.get('availableFormats'),
					callback: function callback(exportColumns, exportFormat) {
						self.get('exportExcel').doExport(data, exportColumns);
					}
				}
			};

			windowManager.newModal(modal);
		},
		fetchAvailableProperties: function fetchAvailableProperties(data) {
			if (data.get('length') === 0) {
				return [];
			}

			var firstObject = data.objectAt(0);

			var allKeys = Object.keys(firstObject.toJSON());

			var attributes = [];

			firstObject.eachAttribute(function (name, meta) {
				if (meta.isAttribute) {
					attributes.push(name);
				}
			});

			return attributes;
		}
	});
});