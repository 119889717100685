define('bd-onko/models/session', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		category: _emberData['default'].belongsTo('sessioncategory', { inverse: null }),
		variant: _emberData['default'].belongsTo('sessionvariant', { inverse: null }),
		author: _emberData['default'].belongsTo('user'),
		customer: _emberData['default'].belongsTo('customer'),
		contactPerson: _emberData['default'].belongsTo('contactperson'),
		updatedAt: _emberData['default'].attr('date'),
		remark: _emberData['default'].attr('string'),
		actualProducts: _emberData['default'].hasMany('sessionproduct', { inverse: 'actualSession' }),
		targetProducts: _emberData['default'].hasMany('sessionproduct', { inverse: 'targetSession' })
	});
});