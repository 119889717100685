define('bd-onko/components/kwp-admin-login-form', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-login-form'],
		authentication: _ember['default'].inject.service(),
		error: null,
		isLoading: false,
		actions: {
			login: function login(username, password) {
				var self = this;

				this.set('error', null);
				this.set('isLoading', true);

				this.get('authentication').authenticate(username, password, function (err) {
					if (err && err.errors) {
						self.set('error', err.errors[0]);
					}

					self.set('isLoading', false);
				});
			}
		}
	});
});