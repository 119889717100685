define('bd-onko/components/kwp-admin-component', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		size: 1,
		classNameBindings: ['size1', 'size2', 'size3', 'size4'],
		classNames: ['kwp-admin-component'],
		size1: _ember['default'].computed('size', function () {
			return this.get('size') === 1;
		}),
		size2: _ember['default'].computed('size', function () {
			return this.get('size') === 2;
		}),
		size3: _ember['default'].computed('size', function () {
			return this.get('size') === 3;
		}),
		size4: _ember['default'].computed('size', function () {
			return this.get('size') === 4;
		}),
		checkSizeConstraints: (function () {
			var size = parseInt(this.get('size'));

			if (isNaN(size)) {
				return this.set('size', 1);
			}

			if (size < 1) {
				return this.set('size', 1);
			}

			if (size > 4) {
				return this.set('size', 4);
			}
		}).observes('size')
	});
});