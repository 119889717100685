define('bd-onko/components/kwp-admin-component-hasone', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-component-hasone'],
		windowManager: _ember['default'].inject.service('window-manager'),
		relatedEntity: null,
		init: function init() {
			this._super.apply(this, arguments);

			this.updateRelatedEntity();
		},
		updateRelatedEntity: (function () {
			if (this.get('currModel') === null) {
				this.set('relatedEntity', null);
			}

			var field = this.get('options.field');

			if (typeof field !== 'string') {
				this.set('relatedEntity', null);
			}

			this.set('relatedEntity', this.get('currModel').get(field));
		}).observes('currModel', 'options.field'),
		actions: {
			pickNew: function pickNew() {
				var self = this;

				var windowManager = this.get('windowManager');
				var targetModelName = this.get('options.modelName');
				var targetColumns = this.get('options.columns');

				var modal = {
					type: 'entity-picker',
					options: {
						modelName: targetModelName,
						columns: targetColumns,
						multiple: false,
						pickCallback: function pickCallback(entities) {
							var field = self.get('options.field');
							self.get('currModel').set(field, entities[0]);
							self.updateRelatedEntity();
						}
					}
				};

				windowManager.newModal(modal);
			},
			remove: function remove(entity) {
				var field = this.get('options.field');
				this.get('currModel').set(field, null);
				this.updateRelatedEntity();
			}
		}
	});
});