define('bd-onko/services/window-manager', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		kwpAdminConfigurate: _ember['default'].inject.service('kwp-admin-configurate'),
		windows: _ember['default'].A([]),
		modals: _ember['default'].A([]),
		activeWindow: null,
		open: function open(windowObj) {
			this.set('activeWindow', windowObj);
		},
		close: function close(windowObj) {
			this.get('windows').removeObject(windowObj);

			if (this.get('activeWindow') !== windowObj) {
				return;
			}

			var firstWindow = this.get('windows').objectAt(0);

			if (typeof firstWindow !== undefined && firstWindow !== null) {
				this.open(firstWindow);
			}
		},
		'new': function _new(windowConfig) {
			var windowObj = new _ember['default'].Object(windowConfig);

			this.get('windows').addObject(windowObj);

			this.set('activeWindow', windowObj);
		},
		newModal: function newModal(modalConfig) {
			var modalObj = new _ember['default'].Object(modalConfig);

			this.get('modals').addObject(modalObj);
		},
		closeModal: function closeModal(modalObj) {
			this.get('modals').removeObject(modalObj);
		}
	});
});