define('bd-onko/components/kwp-admin-modal-alert', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNameBindings: ['aboveCover'],
		classNames: ['kwp-admin-modal-alert'],
		show: true,
		aboveCover: _ember['default'].computed('options.aboveCover', function () {
			if (this.get('options.aboveCover') === true) {
				return true;
			}

			return false;
		}),
		actions: {
			ok: function ok() {
				this.set('show', false);
			}
		}
	});
});