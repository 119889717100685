define('bd-onko/components/kwp-admin-modal-export-wizard', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		show: true,
		columnStates: null,
		init: function init() {
			this._super.apply(this, arguments);

			this.updateColumnStates();
		},
		updateColumnStates: (function () {
			var availableColumns = this.get('modal.options.availableColumns');

			this.set('columnStates', new _ember['default'].Object({}));

			for (var i = 0; i < availableColumns.length; i++) {
				this.get('columnStates').set(availableColumns[i], true);
			}
		}).observes('modal.options.availableColumns'),
		getSelectedColumns: function getSelectedColumns() {
			var columnStates = this.get('columnStates');

			var selected = [];

			for (var column in columnStates) {
				if (columnStates[column] === true) {
					selected.push(column);
				}
			}

			return selected;
		},
		actions: {
			callback: function callback() {
				this.get('modal.options.callback')(this.getSelectedColumns(), 'excel');
				this.send('close');
			},
			close: function close() {
				this.set('show', false);
			},
			changeColumnState: function changeColumnState(column) {
				this.get('columnStates').set(column, false);
			}
		}
	});
});