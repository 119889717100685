define('bd-onko/components/kwp-admin-component-navigation', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		doSelect: (function () {
			var model = this.get('currModel');

			this.set('selectedItems', _ember['default'].A([model]));
		}).observes('currModel'),
		actions: {
			displayDataChanged: function displayDataChanged(e) {
				// Nur Einzelauswahl erlaubt
				var selected = e.selectedItems[0];

				var idx = this.get('collection').indexOf(selected);

				this.set('currIdx', idx);
			}
		}
	});
});