define('bd-onko/adapters/application', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
	exports['default'] = _emberData['default'].RESTAdapter.extend({
		authentication: _ember['default'].inject.service('authentication'),
		host: 'https://app.bdonko.kwp.cloud',
		pathForType: function pathForType(modelName) {
			if (modelName === 'contactperson') {
				return 'contactpersons';
			}

			return _ember['default'].String.pluralize(modelName);
		},
		headers: _ember['default'].computed('localization.locale', 'authentication.token', function () {
			var token = this.get('authentication').get('token');

			var tokenString = null;

			if (typeof token === 'string') {
				tokenString = 'Bearer ' + token;
			}

			return {
				"Content-Type": "application/json",
				"Authorization": tokenString
			};
		}),
		updateRecord: function updateRecord(store, type, snapshot) {
			var self = this;

			var serialized = snapshot.serialize();

			var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');

			return _ember['default'].$.ajax({
				url: url,
				method: "POST",
				headers: self.get('headers'),
				data: JSON.stringify(serialized)
			});
		},
		createRecord: function createRecord(store, type, snapshot) {
			var self = this;

			var serialized = snapshot.serialize();

			var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'createRecord');

			return _ember['default'].$.ajax({
				url: url,
				method: "PUT",
				headers: self.get('headers'),
				data: JSON.stringify(serialized)
			});
		}
	});
});