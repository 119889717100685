define('bd-onko/components/kwp-admin-nav', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-nav'],
		kwpAdminConfigurate: _ember['default'].inject.service(),
		windowManager: _ember['default'].inject.service('window-manager'),
		actions: {
			open: function open(windowConfig) {
				this.get('windowManager')['new'](windowConfig);
			}
		}
	});
});