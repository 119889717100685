define('bd-onko/components/kwp-admin-modal-entity-picker', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-modal-entity-picker'],
		store: _ember['default'].inject.service(),
		isLoading: true,
		collection: _ember['default'].A([]),
		selection: _ember['default'].A([]),
		show: true,
		init: function init() {
			var self = this;

			this._super.apply(this, arguments);

			var modelName = this.get('options.modelName');

			var collection = this.get('store').findAll(modelName);

			this.set('collection', collection);

			collection.then(function () {
				self.set('isLoading', false);
			});
		},
		actions: {
			callback: function callback() {
				this.get('options.pickCallback')(this.get('selection'));
				this.set('show', false);
			},
			displayDataChanged: function displayDataChanged(e) {
				var selected = e.selectedItems;

				this.set('selection', selected);
			}
		}
	});
});