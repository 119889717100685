define('bd-onko/components/kwp-admin-form', ['exports', 'ember', 'npm:async'], function (exports, _ember, _npmAsync) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    'export': _ember['default'].inject.service(),
    classNames: ['kwp-admin-form'],
    classNameBindings: ['navigationView', 'componentsView', 'active'],
    isLoading: true,
    currIdx: 0,
    currModel: null,
    navigationView: true,
    navigationActions: null,
    componentsActions: null,
    errors: null,
    componentsView: _ember['default'].computed('navigationView', function () {
      return !this.get('navigationView');
    }),
    collection: _ember['default'].A([]),
    component: _ember['default'].computed(function () {
      return this;
    }),
    windowTitle: _ember['default'].computed('currModel', 'window.titleField', function () {
      var currModel = this.get('currModel');
      var titleField = this.get('window.titleField');

      if (!currModel || !titleField) {
        return false;
      }

      var value = currModel.get(titleField);

      if (!value) {
        return false;
      }

      return value;
    }),
    updateCurrModel: (function () {
      var currIdx = this.get('currIdx');
      var model = this.get('collection').objectAt(currIdx);

      this.set('currModel', model);
    }).observes('currIdx'),
    updateCurrIdx: (function () {
      var currModel = this.get('currModel');
      var currIdx = this.get('collection').indexOf(currModel);

      this.set('currIdx', currIdx);
    }).observes('currModel', 'collection'),
    setNavigationView: function setNavigationView() {
      this.set('navigationView', true);
    },
    setComponentsView: function setComponentsView() {
      this.set('navigationView', false);
    },
    isDirty: function isDirty() {
      var model = this.get('currModel');

      if (typeof model !== 'object' || model === null) {
        return false;
      }

      return model.changedAttributes().length > 0;
    },
    initializeDefaultActions: function initializeDefaultActions() {
      var navigationActions = new _ember['default'].Object({
        'new': {
          icon: "plus",
          label: "Neu",
          callback: function callback(form) {
            var store = form.get('store');
            var modelName = form.get('window').get('model');

            var newModel = store.createRecord(modelName, {});

            // Timeout ist hier notwendig da modell nicht schnell genug
            // im Store erscheint
            setTimeout(function () {
              form.set('currModel', newModel);
            }, 50);

            form.setComponentsView();
          }
        },
        exportCollection: {
          icon: "table",
          label: "Export",
          callback: function callback(form) {
            var collection = form.get('collection');
            var exportService = form.get('export');

            exportService.startExportWizard(collection);
          }
        }
      });

      this.set('navigationActions', navigationActions);

      var componentsActions = new _ember['default'].Object({
        'delete': {
          icon: "trash",
          label: "Löschen",
          callback: function callback(form) {
            if (!confirm("Möchten Sie diesen Datensatz wirklich löschen? Diese Aktion ist nicht wiederrufbar.")) {
              return;
            }

            form.set('isLoading', true);

            var model = form.get('currModel');

            if (typeof model !== 'object' || model === null) {
              return;
            }

            form.deleteCurrModel().then(function () {
              form.updateCurrModel();
              form.set('isLoading', false);
            }, function (res) {
              form.set('isLoading', false);
              model.rollback();
              form.handleErrorResponse(res);
            });
          }
        },
        save: {
          icon: "floppy-o",
          label: "Speichern",
          callback: function callback(form) {
            var model = form.get('currModel');

            form.set('isLoading', true);
            form.clearErrors();

            if (typeof model !== 'object' || model === null) {
              return;
            }

            form.saveCurrModel().then(function () {
              form.set('isLoading', false);
            }, function (res) {
              form.set('isLoading', false);

              form.handleErrorResponse(res);
            });
          }
        }
      });

      this.set('componentsActions', componentsActions);
    },
    deleteCurrModel: function deleteCurrModel() {
      var model = this.get('currModel');

      // Hole alle datagrids und lösche Einträge
      var components = this.get('window.components');

      return new Promise(function (resolve, reject) {
        var toDelete = [];

        for (var i = components.length - 1; i >= 0; i--) {
          var component = components[i];

          if (component.type !== 'datagrid') {
            continue;
          }

          var datagridRows = model.get(component.options.field);

          datagridRows.forEach(function (row) {
            toDelete.push(row);
          });
        }

        _npmAsync['default'].each(toDelete, function (model, callback) {
          model.destroyRecord().then(function () {
            callback();
          }, function (res) {
            callback('Could not be saved');
          });
        }, function (err) {
          if (err) {
            reject({ errors: ['Nicht alle Anhänge an diesen Datensatz konnten gelöscht werden. Überprüfen Sie die Datagrids auf verbleibende Zeilen.'] });
            return;
          }

          model.destroyRecord().then(function () {
            resolve();
          }, function (res) {
            reject(res);
          });
        });
      });
    },
    saveCurrModel: function saveCurrModel() {
      var self = this;

      var model = this.get('currModel');

      return new Promise(function (resolve, reject) {
        model.save().then(function () {
          self.saveDependencies(model).then(function () {
            model.save().then(resolve, reject);
          }, function (res) {
            reject(res);
          });
        }, function (res) {
          reject(res);
        });
      });
    },
    saveDependencies: function saveDependencies(entity) {
      var self = this;

      var todo = 0;
      var done = 0;

      return new Promise(function (resolve, reject) {
        var check = function check() {
          if (todo === done) {
            resolve();
          }
        };

        entity.eachRelationship(function (name, descriptor) {
          if (['belongsTo', 'hasMany'].indexOf(descriptor.kind) === -1) {
            return;
          }

          todo++;

          var related = _ember['default'].A([]);

          if (descriptor.kind === 'belongsTo') {
            related.addObject(entity.get(name));
          }

          if (descriptor.kind === 'hasMany') {
            related = entity.get(name);
          }

          if (related.get('length') === 0) {
            done++;
            check();
          }

          related.every(function (related) {
            self.saveIfDirty(related).then(function () {
              done++;
              check();
            }, reject);
            return true;
          });
        });

        check();
      });
    },
    saveIfDirty: function saveIfDirty(entity) {
      return new Promise(function (resolve, reject) {
        if (!entity.get('isDirty') && !entity.get('isNew') && !entity.get('isDeleted')) {
          return resolve();
        }

        if (typeof entity.save !== 'function') {
          // Null-Relation
          return resolve();
        }

        entity.save().then(resolve, reject);
      });
    },
    clearErrors: function clearErrors() {
      this.set('currModel._errors', new _ember['default'].Object());
    },
    handleErrorResponse: function handleErrorResponse(response) {
      if (typeof response !== 'object' || typeof response.errors !== 'object' && typeof response.responseJSON !== 'object') {
        this.set('currModel._errors', new _ember['default'].Object({ 0: 'Bei Ihrer Anfrage ist ein allgemeiner Fehler aufgetreten. Bitte versuchen Sie es erneut.' }));
        return;
      }

      var errors = response.errors || response.responseJSON.errors || false;

      if (typeof errors !== 'object') {
        this.set('currModel._errors', new _ember['default'].Object({ 0: 'Bei Ihrer Anfrage ist ein allgemeiner Fehler aufgetreten. Bitte versuchen Sie es erneut.' }));
        return;
      }

      this.set('currModel._errors', new _ember['default'].Object());

      for (var field in errors) {
        if (!errors.hasOwnProperty(field)) {
          continue;
        }

        this.get('currModel._errors').set(field, errors[field]);
      }
    },
    init: function init() {
      var self = this;

      this._super.apply(this, arguments);

      this.set('isLoading', true);

      this.initializeDefaultActions();

      var modelName = this.get('window').get('model');

      var collection = this.get('store').findAll(modelName);

      self.set('collection', collection);

      collection.then(function () {
        self.set('isLoading', false);
      }, function () {
        self.set('isLoading', false);
        self.handleErrorResponse();
      });

      this.mergeNavigationActions();
      this.mergeComponentsActions();

      this.set('errors', new _ember['default'].Object());
    },
    mergeActions: function mergeActions(defaultActions, customActions) {
      customActions = new _ember['default'].Object(customActions);

      var customKeys = Object.keys(customActions);

      for (var i = 0; i < customKeys.length; i++) {
        var key = customKeys[i];

        defaultActions.set(key, customActions.get(key));
      }
    },
    mergeNavigationActions: function mergeNavigationActions() {
      var defaultActions = this.get('navigationActions');
      var customActions = this.get('window').get('navigation.actions');

      this.mergeActions(defaultActions, customActions);
    },
    mergeComponentsActions: function mergeComponentsActions() {
      var defaultActions = this.get('componentsActions');
      var customActions = this.get('window').get('componentsActions');

      this.mergeActions(defaultActions, customActions);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.get('window').set('form', this);
    },
    actions: {
      setComponentsView: function setComponentsView() {
        this.setComponentsView();
      },
      setNavigationView: function setNavigationView() {
        this.setNavigationView();
      },
      next: function next() {
        this.set('currIdx', this.get('currIdx') + 1);
      },
      previous: function previous() {
        this.set('currIdx', this.get('currIdx') - 1);
      }
    }

  });
});