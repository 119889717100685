define('bd-onko/components/kwp-admin-component-hasmany', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-component-hasmany'],
		windowManager: _ember['default'].inject.service('window-manager'),
		inputVal: _ember['default'].computed('currModel', 'options.field', {
			get: function get(key) {
				if (this.get('currModel') === null) {
					return '';
				}

				var field = this.get('options.field');

				if (typeof field !== 'string') {
					return '';
				}

				return this.get('currModel').get(field);
			},
			set: function set(key, value) {
				if (this.get('currModel') === null) {
					return '';
				}

				var field = this.get('options.field');

				if (typeof field !== 'string') {
					return '';
				}

				return this.get('currModel').set(field, value);
			}

		}),
		actions: {
			pickNew: function pickNew() {
				var self = this;

				var windowManager = this.get('windowManager');
				var targetModelName = this.get('options.modelName');
				var targetColumns = this.get('options.columns');

				var modal = {
					type: 'entity-picker',
					options: {
						modelName: targetModelName,
						columns: targetColumns,
						multiple: true,
						pickCallback: function pickCallback(entities) {
							self.get('inputVal').addObjects(entities);
						}
					}
				};

				windowManager.newModal(modal);
			},
			remove: function remove(entity) {
				this.get('inputVal').removeObject(entity);
			}
		}
	});
});