define('bd-onko/components/kwp-admin-userinfo', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-userinfo'],
		authentication: _ember['default'].inject.service(),
		actions: {
			logout: function logout() {
				this.get('authentication').logout();
			}
		}
	});
});