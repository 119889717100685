define('bd-onko/components/kwp-admin-window-tabs', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-window-tabs'],
		windowManager: _ember['default'].inject.service('window-manager'),
		actions: {
			close: function close(windowObj) {
				this.get('windowManager').close(windowObj);
			},
			open: function open(windowObj) {
				this.get('windowManager').open(windowObj);
			}
		}
	});
});