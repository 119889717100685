define('bd-onko/components/kwp-admin-form-error', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-form-error'],
		classNameBindings: ['show'],
		show: _ember['default'].computed('errors', function () {
			var errors = this.get('errors');

			var isNonEmptyObject = typeof errors === 'object' && Object.keys(errors).length > 0;
			var isString = typeof errors === 'string';

			return isNonEmptyObject || isString;
		}),
		errorString: _ember['default'].computed('errors', function () {
			var errors = this.get('errors');

			if (typeof errors !== 'string') {
				return null;
			}

			return errors;
		}),
		errorArray: _ember['default'].computed('errors', function () {
			var errors = this.get('errors');

			if (typeof errors !== 'object') {
				return null;
			}

			return errors;
		})
	});
});