define('bd-onko/router', ['exports', 'ember', 'bd-onko/config/environment'], function (exports, _ember, _bdOnkoConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _bdOnkoConfigEnvironment['default'].locationType,
    rootURL: _bdOnkoConfigEnvironment['default'].rootURL
  });

  Router.map(function () {});

  exports['default'] = Router;
});