define('bd-onko/components/kwp-admin-component-datagrid-cell-hasone', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-component-datagrid-cell-hasone'],
		windowManager: _ember['default'].inject.service('window-manager'),
		actions: {
			pickNew: function pickNew() {
				var self = this;

				var windowManager = this.get('windowManager');
				var targetModelName = this.get('modelName');
				var targetColumns = this.get('pickerColumns');

				var modal = {
					type: 'entity-picker',
					options: {
						modelName: targetModelName,
						columns: targetColumns,
						multiple: false,
						pickCallback: function pickCallback(entities) {
							var field = self.get('field');
							self.get('entity').set(field, entities[0]);
						}
					}
				};

				windowManager.newModal(modal);
			},
			remove: function remove() {
				var field = this.get('field');
				this.get('entity').set(field, null);
			}
		}
	});
});