define('bd-onko/components/kwp-admin-modal-file-upload', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-modal-file-upload'],
		store: _ember['default'].inject.service(),
		authentication: _ember['default'].inject.service(),
		endpoint: _ember['default'].computed('store', function () {
			var host = this.get('store').adapterFor('application').get('host');

			return host + '/files';
		}),
		headers: _ember['default'].computed('authentication.token', function () {
			var token = this.get('authentication.token');

			if (typeof token !== 'string') {
				return {};
			}

			var authorisationString = 'Bearer ' + token;

			return {
				"Authorization": authorisationString
			};
		}),
		show: true,
		maxFiles: _ember['default'].computed('options', function () {
			var maxFiles = this.get('options.maxFiles');

			if (typeof maxFiles !== 'number') {
				return null;
			}

			return maxFiles;
		}),
		actions: {
			onComplete: function onComplete(file) {
				var callback = this.get('options.callback');

				try {
					var fileParams = JSON.parse(file.xhr.response);
				} catch (ex) {
					return callback('Invalid response received', _ember['default'].A([]));
				}

				var store = this.get('store');

				store.findRecord('file', fileParams.id).then(function (file) {
					callback(null, file);
				});
			}
		}
	});
});