define('bd-onko/components/kwp-admin-modal', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-modal'],
		classNameBindings: ['show'],
		show: false,
		closable: true,
		consecutiveRender: false,
		top: null,
		left: null,
		updateStyle: (function () {
			var element = this.get('element');

			element.style.left = this.get('left') + 'px';
			element.style.top = this.get('top') + 'px';
		}).observes('top', 'left'),
		actions: {
			close: function close() {
				this.set('show', false);
			},
			center: function center() {
				this.centerElement();
			}
		},
		centerElement: function centerElement() {
			var viewport = {
				w: window.innerWidth,
				h: window.innerHeight
			};

			var element = this.get('element');

			var dimensions = {
				w: _ember['default'].$(element).outerWidth(),
				h: _ember['default'].$(element).outerHeight()
			};

			var padLeft = (viewport.w - dimensions.w) / 2;
			var padTop = (viewport.h - dimensions.h) / 2;

			this.set('top', padTop);
			this.set('left', padLeft);

			this.updateStyle();
		},
		didRender: function didRender() {
			this._super.apply(this, arguments);

			if (!this.get('consecutiveRender')) {
				this.centerElement();
			}

			this.set('consecutiveRender', true);

			this.bindMove();
		},
		bindMove: function bindMove() {
			var self = this;

			var head = _ember['default'].$(this.get('element')).find('.head');

			var isDragging = false;
			var mouseOffset = {
				x: 0,
				y: 0
			};

			head.mousedown(function (event) {
				isDragging = true;
				mouseOffset.x = event.pageX - self.get('left');
				mouseOffset.y = event.pageY - self.get('top');
			});

			head.mouseup(function () {
				isDragging = false;
			});

			$('body').mousemove(function (event) {
				if (!isDragging) {
					return;
				}

				self.set('left', event.pageX - mouseOffset.x);
				self.set('top', event.pageY - mouseOffset.y);
			});
		}
	});
});