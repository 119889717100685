define('bd-onko/services/export-excel', ['exports', 'ember', 'npm:xlsx'], function (exports, _ember, _npmXlsx) {
	exports['default'] = _ember['default'].Service.extend({
		doExport: function doExport(data, columns) {
			var dataArray = this.buildDataArray(data, columns);

			var workbook = {};

			workbook.Sheets = {};

			workbook.Props = {};

			workbook.SheetNames = [];

			var worksheet = _npmXlsx['default'].utils.aoa_to_sheet(dataArray);

			workbook.SheetNames.push('Export');

			workbook.Sheets['Export'] = worksheet;

			var output = _npmXlsx['default'].write(workbook, {
				bookType: 'xlsx',
				bookSST: true,
				type: 'binary'
			});

			var buffer = new ArrayBuffer(output.length);

			var view = new Uint8Array(buffer);

			for (var i = 0; i != output.length; ++i) {
				view[i] = output.charCodeAt(i) & 0xFF;
			}

			var blob = new Blob([buffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			});

			var a = new FileReader();
			a.onload = function (e) {
				window.open(e.target.result);
			};
			a.readAsDataURL(blob);
		},
		buildDataArray: function buildDataArray(data, columns) {
			var rows = [];

			var newRow = [];

			for (var i = 0; i < columns.length; i++) {
				newRow.push(columns[i]);
			}

			rows.push(newRow);

			data.forEach(function (object) {
				var objectRow = [];

				for (var i = 0; i < columns.length; i++) {
					objectRow.push(object.get(columns[i]));
				}

				rows.push(objectRow);
			});

			return rows;
		}
	});
});