define('bd-onko/app', ['exports', 'ember', 'bd-onko/resolver', 'ember-load-initializers', 'bd-onko/config/environment'], function (exports, _ember, _bdOnkoResolver, _emberLoadInitializers, _bdOnkoConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _bdOnkoConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _bdOnkoConfigEnvironment['default'].podModulePrefix,
    Resolver: _bdOnkoResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _bdOnkoConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});