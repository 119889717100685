define('bd-onko/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		address1: _emberData['default'].attr('string'),
		address2: _emberData['default'].attr('string'),
		zip: _emberData['default'].attr('string'),
		city: _emberData['default'].attr('string'),
		telephone: _emberData['default'].attr('string'),
		eMail: _emberData['default'].attr('string'),
		contactpersons: _emberData['default'].hasMany('contactperson', { inverse: 'customer' })
	});
});