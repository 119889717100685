define('bd-onko/models/product', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		title: _emberData['default'].attr('string'),
		artNo: _emberData['default'].attr('string'),
		PZN: _emberData['default'].attr('string'),
		brand: _emberData['default'].attr('string'),
		isPlaceholder: _emberData['default'].attr('boolean'),
		description: _emberData['default'].attr('string'),
		bullets: _emberData['default'].attr('string'),
		categories: _emberData['default'].hasMany('category', { inverse: 'products' }),
		images: _emberData['default'].hasMany('file'),
		datasheets: _emberData['default'].hasMany('file'),
		attributes: _emberData['default'].hasMany('productattribute', { inverse: 'product' }),
		previousCategories: _emberData['default'].hasMany('category'),
		previousProducts: _emberData['default'].hasMany('product')
	});
});