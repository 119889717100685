define('bd-onko/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		title: _emberData['default'].attr('string'),
		description: _emberData['default'].attr('string'),
		advantages: _emberData['default'].hasMany('advantage'),
		parent: _emberData['default'].belongsTo('category', { inverse: 'children' }),
		children: _emberData['default'].hasMany('category', { inverse: 'parent' }),
		products: _emberData['default'].hasMany('product', { inverse: 'categories' }),
		advantages: _emberData['default'].hasMany('advantage', { inverse: 'categories' }),
		sessionCategories: _emberData['default'].hasMany('sessioncategory')
	});
});