define('bd-onko/instance-initializers/config', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(app) {
    var container = app.lookup ? app : app.container;
    var kwpAdminConfigurate = container.lookup('service:kwp-admin-configurate');
    var fileRepository = container.lookup('service:file-repository');
    var store = container.lookup('service:store');
    var windowManager = container.lookup('service:window-manager');

    kwpAdminConfigurate.set('logo', '/assets/images/logo.png');
    kwpAdminConfigurate.set('title', 'Onko Workflow');

    fileRepository.set('baseUrl', 'https://static.bdonko.kwp.cloud');
    fileRepository.set('authenticationSuffix', '/');

    kwpAdminConfigurate.addWindow({
      title: "Ansprechpartner",
      model: "contactperson",
      titleField: "name",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "name",
          "title": "Name"
        }, {
          "propertyName": "position",
          "title": "Position"
        }, {
          "propertyName": "eMail",
          "title": "E-Mail"
        }, {
          "propertyName": "telephone",
          "title": "Telefon"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "name",
          label: "Name"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "position",
          label: "Position"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "telephone",
          label: "Telefon"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "fax",
          label: "Fax"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "eMail",
          label: "E-Mail"
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "customer",
          label: "Kunde",
          labelField: "name",
          modelName: "customer",
          columns: [{
            "propertyName": "name",
            "title": "Name"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Kunden",
      model: "customer",
      titleField: "name",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "name",
          "title": "Firma"
        }, {
          "propertyName": "address1",
          "title": "Adresszeile 1"
        }, {
          "propertyName": "address2",
          "title": "Adresszeile 2"
        }, {
          "propertyName": "zip",
          "title": "PLZ"
        }, {
          "propertyName": "city",
          "title": "Ort"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "name",
          label: "Firma"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "address1",
          label: "Adresszeile 1"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "address2",
          label: "Adresszeile 2"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "zip",
          label: "PLZ"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "city",
          label: "Ort"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "telephone",
          label: "Telefon"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "eMail",
          label: "E-Mail"
        }
      }, {
        type: "hasmany",
        size: 4,
        options: {
          field: "contactpersons",
          label: "Ansprechpartner",
          labelField: "name",
          readOnly: true
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Produkte",
      model: "product",
      titleField: "title",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "artNo",
          "title": "Artikel-Nr."
        }, {
          "propertyName": "title",
          "title": "Bezeichnung"
        }, {
          "propertyName": "brand",
          "title": "Marke"
        }]
      },
      components: [{
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "artNo",
          label: "Artikel-Nr"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "PZN",
          label: "PZN"
        }
      }, {
        type: "input",
        size: 2,
        options: {
          format: "text",
          field: "title",
          label: "Bezeichnung"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "brand",
          label: "Marke"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "checkbox",
          field: "isPlaceholder",
          label: "Platzhalterprodukt"
        }
      }, {
        type: "hasone",
        size: 4,
        options: {
          field: "parent",
          label: "Übergeordnetes Produkt",
          labelField: "title",
          modelName: "product",
          columns: [{
            "propertyName": "artNo",
            "title": "Art.-Nr."
          }, {
            "propertyName": "PZN",
            "title": "PZN"
          }, {
            "propertyName": "title",
            "title": "Bezeichnung"
          }]
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "description",
          label: "Zweckbestimmung"
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "bullets",
          label: "Beschreibung"
        }
      }, {
        type: "hasmany",
        size: 1,
        options: {
          field: "categories",
          label: "Kategorien",
          labelField: "title",
          modelName: "category",
          columns: [{
            "propertyName": "title",
            "title": "Name"
          }]
        }
      }, {
        type: "hasmany",
        size: 1,
        options: {
          field: "previousCategories",
          label: "Vorhergehende Kategorien",
          labelField: "title",
          modelName: "category",
          columns: [{
            "propertyName": "title",
            "title": "Name"
          }]
        }
      }, {
        type: "hasmany",
        size: 2,
        options: {
          field: "previousProducts",
          label: "Vorhergehende Produkte",
          labelField: "title",
          modelName: "product",
          columns: [{
            "propertyName": "artNo",
            "title": "Art.-Nr."
          }, {
            "propertyName": "PZN",
            "title": "PZN"
          }, {
            "propertyName": "title",
            "title": "Bezeichnung"
          }]
        }
      }, {
        type: "hasmany",
        size: 4,
        options: {
          field: "images",
          label: "Bilder",
          labelField: "fileName",
          modelName: "file",
          columns: [{
            "propertyName": "title",
            "title": "Titel"
          }, {
            "propertyName": "fileName",
            "title": "Dateiname"
          }, {
            "propertyName": "tagString",
            "title": "Tags"
          }]
        }
      }, {
        type: "hasmany",
        size: 4,
        options: {
          field: "datasheets",
          label: "Datenblätter",
          labelField: "fileName",
          modelName: "file",
          columns: [{
            "propertyName": "title",
            "title": "Titel"
          }, {
            "propertyName": "fileName",
            "title": "Dateiname"
          }, {
            "propertyName": "tagString",
            "title": "Tags"
          }]
        }
      }, {
        type: "datagrid",
        size: 4,
        options: {
          field: "attributes",
          label: "Eigenschaften",
          modelName: "productattribute",
          injectCurrModelInto: ['product'],
          columns: [{
            "propertyName": "attribute",
            "title": "Attribut",
            "modelName": "attribute",
            "labelField": "name",
            "pickerColumns": [{
              "propertyName": "name",
              "title": "Name"
            }]
          }, {
            "propertyName": "value",
            "title": "Wert"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Prod.-Statistik",
      model: "productstatistic",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "artNo",
          "title": "Art.Nr."
        }, {
          "propertyName": "usage",
          "title": "Verwendung Gesamt"
        }, {
          "propertyName": "usage30Days",
          "title": "Verwendung 30 Tage"
        }, {
          "propertyName": "usageYear",
          "title": "Verwendung 1 Jahr"
        }]
      },
      components: []
    });

    kwpAdminConfigurate.addWindow({
      title: "Kategorien",
      model: "category",
      titleField: "title",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "title",
          "title": "Name"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "title",
          label: "Titel"
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "description",
          label: "Beschreibung"
        }
      }, {
        type: "hasmany",
        size: 4,
        options: {
          field: "advantages",
          label: "Zugeordnete Vorteile",
          labelField: "title",
          modelName: "advantage",
          columns: [{
            "propertyName": "title",
            "title": "Name"
          }]
        }
      }, {
        type: "hasone",
        size: 4,
        options: {
          field: "parent",
          label: "Übergeordnete Kategorie",
          labelField: "title",
          modelName: "category",
          columns: [{
            "propertyName": "title",
            "title": "Name"
          }]
        }
      }, {
        type: "hasmany",
        size: 4,
        options: {
          field: "children",
          label: "Unterkategorien",
          labelField: "title",
          readOnly: true
        }
      }, {
        type: "hasmany",
        size: 1,
        options: {
          field: "sessionCategories",
          label: "Workflowkategorien",
          labelField: "title",
          modelName: "sessioncategory",
          columns: [{
            "propertyName": "title",
            "title": "Name"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Dokumente",
      model: "document",
      titleField: "title",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "title",
          "title": "Name"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "title",
          label: "Titel"
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "description",
          label: "Beschreibung"
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "file",
          label: "Datei",
          labelField: "fileName",
          modelName: "file",
          columns: [{
            "propertyName": "title",
            "title": "Titel"
          }, {
            "propertyName": "fileName",
            "title": "Dateiname"
          }, {
            "propertyName": "tagString",
            "title": "Tags"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Vorteile",
      model: "advantage",
      titleField: "title",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "title",
          "title": "Name"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "title",
          label: "Titel"
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "description",
          label: "Beschreibung"
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Attribute",
      model: "attribute",
      titleField: "name",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "name",
          "title": "Name"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "name",
          label: "Bezeichnung"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "type",
          label: "Art"
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Dateien",
      model: "file",
      titleField: "title",
      navigation: {
        enabled: true,
        actions: {
          'new': {
            icon: "upload",
            label: "Hochladen",
            callback: function callback(form) {
              var modal = {
                type: 'file-upload',
                options: {
                  callback: function callback(err, file) {
                    if (err) {
                      alert(err);
                    }

                    // Timeout ist hier notwendig da modell nicht schnell genug
                    // im Store erscheint
                    setTimeout(function () {
                      form.set('currModel', file);
                    }, 50);
                  }
                }
              };

              windowManager.newModal(modal);
            }
          }
        },
        columns: [{
          "propertyName": "title",
          "title": "Titel"
        }, {
          "propertyName": "fileName",
          "title": "Dateiname"
        }, {
          "propertyName": "author.fullName",
          "title": "Autor"
        }, {
          "propertyName": "tagString",
          "title": "Tags"
        }]
      },
      componentsActions: {
        download: {
          icon: "download",
          label: "Herunterladen",
          callback: function callback(form) {
            var currModel = form.get('currModel');

            if (typeof currModel !== 'object') {
              return;
            }

            var url = fileRepository.getUrl(form.get('currModel'));

            window.open(url);
          }
        }
      },
      components: [{
        type: "input",
        size: 2,
        options: {
          format: "text",
          field: "fileName",
          label: "Dateiname",
          readOnly: true
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "size",
          label: "Größe",
          readOnly: true
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "author",
          label: "Autor",
          labelField: "fullName",
          modelName: "user",
          readOnly: true
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "title",
          label: "Titel"
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "description",
          label: "Beschreibung"
        }
      }, {
        type: "hasmany",
        size: 4,
        options: {
          field: "tags",
          label: "Tags",
          labelField: "name",
          modelName: "tag",
          columns: [{
            "propertyName": "name",
            "title": "Name"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Tags",
      model: "tag",
      titleField: "name",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "name",
          "title": "Name"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "name",
          label: "Name"
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Benutzer",
      model: "user",
      titleField: "eMail",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "id",
          "title": "ID"
        }, {
          "propertyName": "fullName",
          "title": "Name"
        }, {
          "propertyName": "eMail",
          "title": "E-Mail"
        }]
      },
      components: [{
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "eMail",
          label: "E-Mail"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "password",
          label: "Passwort (leer lassen für keine Änderung)"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "text",
          field: "fullName",
          label: "Name"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "checkbox",
          field: "isAdmin",
          label: "Ist Administrator"
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "checkbox",
          field: "isActive",
          label: "Ist Aktiv"
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Sitzungen",
      model: "session",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "category.title",
          "title": "Kategorie"
        }, {
          "propertyName": "variant.title",
          "title": "Variante"
        }, {
          "propertyName": "author.fullName",
          "title": "Mitarbeiter"
        }, {
          "propertyName": "customer.name",
          "title": "Kunde"
        }, {
          "propertyName": "updatedAt",
          "title": "Aktualisiert"
        }]
      },
      components: [{
        type: "hasone",
        size: 1,
        options: {
          field: "category",
          label: "Kategorie",
          labelField: "title",
          modelName: "sessioncategory",
          columns: [{
            "propertyName": "title",
            "title": "Bezeichnung"
          }]
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "variant",
          label: "Variante",
          labelField: "title",
          modelName: "sessionvariant",
          columns: [{
            "propertyName": "title",
            "title": "Bezeichnung"
          }]
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "author",
          label: "Mitarbeiter",
          labelField: "fullName",
          modelName: "user",
          columns: [{
            "propertyName": "fullName",
            "title": "Name"
          }, {
            "propertyName": "eMail",
            "title": "E-Mail"
          }]
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "customer",
          label: "Kunde",
          labelField: "name",
          modelName: "customer",
          columns: [{
            "propertyName": "name",
            "title": "Name"
          }, {
            "propertyName": "city",
            "title": "Ort"
          }]
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "contactPerson",
          label: "Ansprechpartner",
          labelField: "name",
          modelName: "contactperson",
          columns: [{
            "propertyName": "name",
            "title": "Name"
          }, {
            "propertyName": "position",
            "title": "Stelle"
          }]
        }
      }, {
        type: "input",
        size: 1,
        options: {
          format: "datetime",
          field: "updatedAt",
          label: "Aktualisiert",
          readOnly: true
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "textarea",
          field: "remark",
          label: "Notizen"
        }
      }, {
        type: "datagrid",
        size: 4,
        options: {
          field: "actualProducts",
          label: "IST Workflow",
          modelName: "sessionproduct",
          injectCurrModelInto: ['actualSession'],
          sortingColumn: 'orderNo',
          enableSorting: true,
          columns: [{
            "propertyName": "product",
            "title": "Produkt",
            "modelName": "product",
            "labelField": "title",
            "pickerColumns": [{
              "propertyName": "artNo",
              "title": "Art.Nr."
            }, {
              "propertyName": "title",
              "title": "Bezeichnung"
            }]
          }, {
            "propertyName": "text",
            "title": "Freitext"
          }]
        }
      }, {
        type: "datagrid",
        size: 4,
        options: {
          field: "targetProducts",
          label: "SOLL Workflow",
          modelName: "sessionproduct",
          injectCurrModelInto: ['targetSession'],
          sortingColumn: 'orderNo',
          enableSorting: true,
          columns: [{
            "propertyName": "product",
            "title": "Produkt",
            "modelName": "product",
            "labelField": "title",
            "pickerColumns": [{
              "propertyName": "artNo",
              "title": "Art.Nr."
            }, {
              "propertyName": "title",
              "title": "Bezeichnung"
            }]
          }, {
            "propertyName": "text",
            "title": "Freitext"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Sitzungskategorien",
      model: "sessioncategory",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "title",
          "title": "Bezeichnung"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "title",
          label: "Bezeichnung"
        }
      }, {
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "orderNo",
          label: "Sortierung"
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "session",
          label: "BD-Sitzung",
          labelField: "updatedAt",
          modelName: "session",
          columns: [{
            "propertyName": "category.title",
            "title": "Kategorie"
          }, {
            "propertyName": "variant.title",
            "title": "Variante"
          }, {
            "propertyName": "author.fullName",
            "title": "Autor"
          }, {
            "propertyName": "customer.name",
            "title": "Kunde"
          }, {
            "propertyName": "contactPerson.name",
            "title": "Kontakt"
          }, {
            "propertyName": "updatedAt",
            "title": "Aktualisiert"
          }]
        }
      }]
    });

    kwpAdminConfigurate.addWindow({
      title: "Sitzungsvarianten",
      model: "sessionvariant",
      navigation: {
        enabled: true,
        columns: [{
          "propertyName": "title",
          "title": "Bezeichnung"
        }]
      },
      components: [{
        type: "input",
        size: 4,
        options: {
          format: "text",
          field: "title",
          label: "Bezeichnung"
        }
      }, {
        type: "hasone",
        size: 1,
        options: {
          field: "session",
          label: "BD-Sitzung",
          labelField: "updatedAt",
          modelName: "session",
          columns: [{
            "propertyName": "category.title",
            "title": "Kategorie"
          }, {
            "propertyName": "variant.title",
            "title": "Variante"
          }, {
            "propertyName": "author.fullName",
            "title": "Autor"
          }, {
            "propertyName": "customer.name",
            "title": "Kunde"
          }, {
            "propertyName": "contactPerson.name",
            "title": "Kontakt"
          }, {
            "propertyName": "updatedAt",
            "title": "Aktualisiert"
          }]
        }
      }]
    });
  }

  exports['default'] = {
    name: 'config',
    initialize: initialize
  };
});