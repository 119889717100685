define('bd-onko/services/file-repository', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		authentication: _ember['default'].inject.service(),
		baseUrl: null,
		authenticationSuffix: false,
		getCleanUrl: function getCleanUrl(file) {
			if (typeof file !== 'object') {
				return '';
			}

			return this.get('baseUrl') + '/' + file.get('filePrefix') + '/' + file.get('fileName');
		},
		getAuthSegment: function getAuthSegment() {
			if (!this.get('authenticationSuffix')) {
				return '';
			}

			return this.get('authenticationSuffix') + this.get('authentication').get('token');
		},
		getUrl: function getUrl(file) {
			if (typeof file !== 'object') {
				return '';
			}

			return this.getCleanUrl(file) + this.getAuthSegment();
		},
		getPreviewUrl: function getPreviewUrl(file) {
			if (typeof file !== 'object') {
				return '';
			}
			return this.getCleanUrl(file) + '_preview.jpg';
		}
	});
});