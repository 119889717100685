define('bd-onko/components/kwp-admin-component-datagrid', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		store: _ember['default'].inject.service(),
		classNames: ['kwp-admin-component-datagrid'],
		moveEntity: function moveEntity(entity, direction) {
			direction = parseInt(direction);

			if (isNaN(direction) || direction < -1 || direction > 1) {
				direction = 0;
			}

			var field = this.get('options.field');
			var collection = this.get('currModel').get(field);

			var sortingField = this.get('options.sortingColumn');

			var sortedCollection = collection.sortBy(sortingField);

			var originalIndex = sortedCollection.indexOf(entity);

			if (originalIndex === -1) {
				return;
			}

			for (var i = 0; i < sortedCollection.length; i++) {
				if (i === originalIndex - 1 && direction === -1) {
					sortedCollection[i].set(sortingField, i + 1);
					continue;
				}

				if (i === originalIndex) {
					sortedCollection[i].set(sortingField, i + direction);
					continue;
				}

				if (i === originalIndex + 1 && direction === 1) {
					sortedCollection[i].set(sortingField, i - 1);
					continue;
				}
			}
		},
		actions: {
			add: function add() {
				var store = this.get('store');
				var field = this.get('options.field');
				var modelName = this.get('options.modelName');
				var injectors = this.get('options.injectCurrModelInto');

				var collection = this.get('currModel').get(field);

				var presetFields = {};

				for (var i = injectors.length - 1; i >= 0; i--) {
					presetFields[injectors[i]] = this.get('currModel');
				}

				var newModel = store.createRecord(modelName, presetFields);

				collection.addObject(newModel);
			},
			remove: function remove(entity) {
				entity.deleteRecord();
			},
			sortUp: function sortUp(entity) {
				this.moveEntity(entity, -1);
			},
			sortDown: function sortDown(entity) {
				this.moveEntity(entity, 1);
			}
		}
	});
});