define('bd-onko/components/kwp-admin-component-input', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['kwp-admin-component-input'],
		inputVal: _ember['default'].computed('currModel', 'options.field', {
			get: function get(key) {
				if (this.get('currModel') === null) {
					return '';
				}

				var field = this.get('options.field');

				if (typeof field !== 'string') {
					return '';
				}

				return this.get('currModel').get(field);
			},
			set: function set(key, value) {
				if (this.get('currModel') === null) {
					return '';
				}

				var field = this.get('options.field');

				if (typeof field !== 'string') {
					return '';
				}

				return this.get('currModel').set(field, value);
			}

		})
	});
});